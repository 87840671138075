import React, { useState } from 'react';
import {useForm} from 'react-hook-form';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import FormContact from '../components/Contact/FormContact';
import MapContact from '../components/Contact/MapContact';

const Contact = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <Layout bodyClass="page-default-single">
      <div className="Title">
        <div className="row justify-content-start">
        <div className="col-12 col-md-7 col-lg-6 order-1 order-md-1">
            <span> <h1> </h1> </span>
            <br/>
            <br/>
            </div>
            </div>
        </div>
      <div className="container">
        <div className="row justify-content-centre">
          <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
            <MapContact/>
            </div>
            <div className="col-10 col-md-6 col-lg-5 order-1 order-md-2 position-relative">
            <FormContact />
           </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      fields {
        slug
      }
      html
    }
  }
`;

export default Contact;
