import GatsbyLink from 'gatsby-link';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Link from 'gatsby';

const UNREALINFO_GATEWAY_URL =
  'https://pik5uvgmd5.execute-api.ap-southeast-2.amazonaws.com/Prod';
const required = 'This field is required';

export default function FormContact() {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = async data => {
    try {
      await fetch(UNREALINFO_GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      setSubmitted(true);
      reset();
    } catch (errors) {
      setError(
        'submit',
        'submitError',
        `Oops! There seems to be an issue! ${errors.message}`
      );
    }
  };

  const showSubmitError = msg => <p className="msg-error">{msg}</p>;

  const showThankYou = (
    <div className="msg-confirm">
      <p>Thank you for getting in touch. Your message has been sent.</p>
      <button type="button" onClick={() => setSubmitted(false)}>
        Send another message?
      </button>
    </div>
  );

  const showForm = (
    <div className="container-fluid">
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <div className="page contact-page">
          <input
            id="name"
            type="text"
            placeholder="Name"
            // aria-invalid={errors.name ? "true" : "false"}
            {...register('name', { required: true, maxLength: 100 })}
          />
          {errors.name && (
            <span className="msg-error">
            We cannot respond to anonymous queries!
            </span> 
          )}
          <br />

          <input
            type="text"
            placeholder="Email"
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.email && (
            <span className="msg-error"> We need an email to respond to!</span>
          )}
          <br />

          <input
            type="tel"
            placeholder="Mobile number"
            {...register('mobile', {
              required: false,
              pattern: [0 - 9],
              minLength: 10,
              maxLength: 10
            })}
          />
          {errors.mobile && (
            <span className="msg-error"> Please check your number is 10 digits</span>
          )}
          <br />

          <textarea
            placeholder="Type your message here"
            {...register('message', { required: true })}
          />
          {errors.message && (
            <span className="msg-error">
              {' '}
              Please tell us something before submitting!{' '}
            </span>
          )}
          <br />
          <div className="submit-wrapper">
            <button type="submit" disabled={isSubmitting}>
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div className="page contact-page">
      <div className="msg-confirm">
      <section>
        {/* <br />
        <br />
        <br />
        <br />   */}
        <h2form> Submit an inquiry</h2form>
        <br />
        <br />
        <a href="/signup/" className="button">Click here to request a machine installation</a>
        <br />
        <br />
        <h4form> Otherwise, please enter your inquiry below</h4form>
        <br />        
        <br />        
        {errors && errors.submit && showSubmitError(errors.submit.message)}
        </section>
      </div>
      <div className="form-side">{submitted ? showThankYou : showForm}</div>
    </div>
  );
}
